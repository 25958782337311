import React, { useState } from 'react';
//import bridge from '@vkontakte/vk-bridge';
import View from '@vkontakte/vkui/dist/components/View/View';

import '@vkontakte/vkui/dist/vkui.css';

import Home from './panels/Home';
import CreatePodcast from './panels/CreatePodcast';
import AudioEdit from './panels/AudioEdit';
import PodcastPage from './panels/PodcastPage';
import End from './panels/End';


const App = () => {
	const [activePanel, setActivePanel] = useState(localStorage.getItem('route') || 'home');
	//const [fetchedUser, setUser] = useState(null);
	/*
	useEffect(() => {
		bridge.subscribe(({ detail: { type, data } }) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				document.body.attributes.setNamedItem(schemeAttribute);
			}
		});
		async function fetchData () {
			const user = await bridge.send('VKWebAppGetUserInfo');
			setUser(user);
			setPopout(null);
		}
		fetchData();
	}, []);
	*/

	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
		localStorage.setItem('route', e.currentTarget.dataset.to);
	};

	return (
		<View activePanel={activePanel}>
			<Home id='home' go={go} />
			<CreatePodcast id='createPodcast' go={go} />
			<AudioEdit id='audioEdit' go={setActivePanel} />
			<PodcastPage id='podcastPage' go={go} />
			<End id='end' go={go} />
		</View>
	);
}

export default App;

