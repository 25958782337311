import React from "react";
import { Panel, PanelHeader } from "@vkontakte/vkui";

import Button from "@vkontakte/vkui/dist/components/Button/Button";

import Placeholder from "@vkontakte/vkui/dist/components/Placeholder/Placeholder";
import Icon56CheckCircleOutline from "@vkontakte/icons/dist/56/check_circle_outline";

const End = ({ id, go }) => {
  return (
    <Panel id={id}>
      <PanelHeader>Новый подкаст</PanelHeader>

      <Placeholder
        icon={<Icon56CheckCircleOutline fill="var(--accent)" />}
        header="Подкаст добавлен"
        stretched
        action={
          <Button size="l" onClick={go} data-to="home">
            Поделиться подкастом
          </Button>
        }
      >
        Раскажите своим подписчикам о новом подкасте, чтобы получить больше
        слушателей.
      </Placeholder>
    </Panel>
  );
};

export default End;
