import React, { useState, useEffect, useRef } from "react";

import { platform, IOS, Card, Button, Div } from "@vkontakte/vkui";

import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import PanelHeaderButton from "@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton";

import Icon28ChevronBack from "@vkontakte/icons/dist/28/chevron_back";
import Icon24Back from "@vkontakte/icons/dist/24/back";

import Icon28Play from "@vkontakte/icons/dist/28/play";
import Icon28MagicWandOutline from "@vkontakte/icons/dist/28/magic_wand_outline";

import WaveSurfer from "wavesurfer.js";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js";
import RegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min.js";

import "./AudioEdit.css";

import Timecodes from "../components/Timecodes";

import { cut } from "../utils";

const osName = platform();

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

const AudioEdit = ({ id, go }) => {
  const [audio] = useState(JSON.parse(localStorage.getItem("audio")));
  const [selectedRegion, setSelectedRegion] = useState(null);
  /*
  const setAudio = (audioItem) => {
    setAudioWrap(audioItem);
    localStorage.setItem("audio", JSON.stringify(audioItem));
  };
  */

  if (!audio) {
    go("createPodcast");
    localStorage.setItem("route", "createPodcast");
  }

  let audioTag = new Audio();
  audioTag.addEventListener("loadedmetadata", () => {});
  audioTag.src = audio.file;
  const wavesurfer = useRef(null);
  let regions = [];

  useEffect(() => {
    wavesurfer.current = WaveSurfer.create({
      container: "#waveform",
      waveColor: "#3F8AE0",
      progressColor: "#3F8AE0",
      cursorColor: "#FF3347",
      barWidth: 3,
      barRadius: 3,
      cursorWidth: 1,
      height: 96,
      barGap: 3,
      plugins: [
        RegionsPlugin.create({
          regiosn: [],
          enableDragSelection: false,
        }),
        TimelinePlugin.create({
          container: "#wave-timeline",
        }),
      ],
    });

    wavesurfer.current.loadBlob(dataURItoBlob(audio.file));
    wavesurfer.current.on("ready", () => {
      wavesurfer.current.playPause();
      wavesurfer.current.playPause();
      console.log("load");
    });
    wavesurfer.current.on("dblclick", () => {
      console.log("dblclick");
    });
    return () => {
      wavesurfer.current.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cutRegion = () => {
    if (selectedRegion) {
      console.log(selectedRegion);
      let { newAudioBuffer } = cut(
        {
          start: selectedRegion.start,
          end: selectedRegion.end,
        },
        wavesurfer.current
      );
      selectedRegion.remove();
      wavesurfer.current.loadDecodedBuffer(newAudioBuffer);
      wavesurfer.current.fireEvent("redraw");
      setSelectedRegion(null);
    }
  };

  const createRegion = () => {
    let region = wavesurfer.current.addRegion({
      start: 1,
      end: 3,
      loop: false,
      handleStyle: {
        left: { width: "6px", background: "#3F8AE0" },
        right: {
          width: "6px",
          background: "#3F8AE0",
        },
      },
      color: "rgba(0, 0, 0, 0)",
    });
    region.on("click", (e) => {
      console.log("click");
      setSelectedRegion(region);
    });
    regions.push(region);
    setSelectedRegion(region);
  };

  const back = (e) => {
    wavesurfer.current.destroy();
    localStorage.setItem("route", e.currentTarget.dataset.to);
    go(e.currentTarget.dataset.to);
  };

  return (
    <Panel id={id}>
      <PanelHeader
        left={
          <PanelHeaderButton onClick={back} data-to="createPodcast">
            {osName === IOS ? <Icon28ChevronBack /> : <Icon24Back />}
          </PanelHeaderButton>
        }
      >
        Редактирование
      </PanelHeader>
      <Div>
        <Card mode="outline" className="">
          <div id="wave-timeline"></div>
          <div id="waveform" onDoubleClick={(e) => createRegion()}></div>

          <Button onClick={(e) => wavesurfer.current.playPause()}>
            <Icon28Play />
          </Button>
          <Button
            mode="secondary"
            disabled={selectedRegion ? false : true}
            onClick={(e) => cutRegion()}
          >
            <Icon28MagicWandOutline />
          </Button>
        </Card>
      </Div>
      <Timecodes />
    </Panel>
  );
};

export default AudioEdit;
