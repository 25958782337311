import React, { useState } from "react";

import { platform, IOS, Separator } from "@vkontakte/vkui";

import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import PanelHeaderButton from "@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton";

import RichCell from "@vkontakte/vkui/dist/components/RichCell/RichCell";
import SimpleCell from "@vkontakte/vkui/dist/components/SimpleCell/SimpleCell";

import FormLayout from "@vkontakte/vkui/dist/components/FormLayout/FormLayout";
import Input from "@vkontakte/vkui/dist/components/Input/Input";
import Checkbox from "@vkontakte/vkui/dist/components/Checkbox/Checkbox";
import Textarea from "@vkontakte/vkui/dist/components/Textarea/Textarea";

import InfoRow from "@vkontakte/vkui/dist/components/InfoRow/InfoRow";

import Button from "@vkontakte/vkui/dist/components/Button/Button";

import Icon28ChevronBack from "@vkontakte/icons/dist/28/chevron_back";
import Icon24Back from "@vkontakte/icons/dist/24/back";

import Icon24ChevronRight from "@vkontakte/icons/dist/24/chevron_right";

import Group from "@vkontakte/vkui/dist/components/Group/Group";

import ImagePicker from "../components/ImagePicker";
import AudioPicker from "../components/AudioPicker";

const osName = platform();

const CreatePodcast = ({ id, go }) => {
  const [image, setImageWraper] = useState(localStorage.getItem("image"));
  const [name, setNameWraper] = useState(localStorage.getItem("name") || "");
  const [desc, setDescWraper] = useState(localStorage.getItem("desc") || "");

  const [audio, setAudioWrap] = useState(
    JSON.parse(localStorage.getItem("audio"))
  );

  const setDesc = (desc) => {
    setDescWraper(desc);
    localStorage.setItem("desc", desc);
  };

  const setName = (name) => {
    setNameWraper(name);
    localStorage.setItem("name", name);
  };

  const setImage = (image) => {
    setImageWraper(image);
    localStorage.setItem("image", image);
  };

  const setAudio = (audioItem) => {
    setAudioWrap(audioItem);
    localStorage.setItem("audio", JSON.stringify(audioItem));
  };

  return (
    <Panel id={id}>
      <PanelHeader
        left={
          <PanelHeaderButton onClick={go} data-to="home">
            {osName === IOS ? <Icon28ChevronBack /> : <Icon24Back />}
          </PanelHeaderButton>
        }
      >
        Новый подкаст
      </PanelHeader>
      <FormLayout>
        <RichCell
          disabled
          before={<ImagePicker image={image} setImage={setImage} />}
        >
          <InfoRow header={<div style={{ padding: "0px 12px" }}>Название</div>}>
            <Input
              top="Название"
              type="text"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Введите название подкаста"
            />
          </InfoRow>
        </RichCell>

        <Textarea
          top="Описание подкаста"
          defaultValue={desc}
          onChange={(e) => setDesc(e.target.value)}
        ></Textarea>

        <AudioPicker go={go} audio={audio} setAudio={setAudio} />
        <Separator />
        <Group>
          <Checkbox>Ненормативный контент</Checkbox>
          <Checkbox>Исключить эпизод из экспорта</Checkbox>
          <Checkbox>Трейлер подкаста</Checkbox>
        </Group>
        <Group description="При публикации записи с эпизодом, он становится доступным для всех пользователей.">
          <SimpleCell
            description="Всем пользователям"
            after={<Icon24ChevronRight fill="var(--icon_tertiary)" />}
          >
            Кому доступен данный подкаст
          </SimpleCell>
        </Group>
        <Button size="xl" onClick={go} data-to="podcastPage">
          Далее
        </Button>
      </FormLayout>
    </Panel>
  );
};

export default CreatePodcast;
