import React from "react";
import PropTypes from "prop-types";
import Panel from "@vkontakte/vkui/dist/components/Panel/Panel";
import PanelHeader from "@vkontakte/vkui/dist/components/PanelHeader/PanelHeader";
import Button from "@vkontakte/vkui/dist/components/Button/Button";

import Placeholder from "@vkontakte/vkui/dist/components/Placeholder/Placeholder";
import Icon56AddCircleOutline from "@vkontakte/icons/dist/56/add_circle_outline";

const Home = ({ id, go }) => {
  localStorage.removeItem("desc");
  localStorage.removeItem("name");
  localStorage.removeItem("image");
  localStorage.removeItem("times");
  localStorage.removeItem("audio");

  return (
    <Panel id={id}>
      <PanelHeader>Подкасты</PanelHeader>

      <Placeholder
        icon={<Icon56AddCircleOutline />}
        header="Добавьте первый подкаст"
        stretched
        action={
          <Button size="l" onClick={go} data-to="createPodcast">
            Добавить подкаст
        </Button>
        }
      >
        Добавляйте, редактируйте и делитесь подкастами вашего сообщества.
    </Placeholder>
    </Panel>
  )
};

Home.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired,
  fetchedUser: PropTypes.shape({
    photo_200: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    city: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

export default Home;
