import React from "react";

import {
  platform,
  IOS,
  Panel,
  PanelHeader,
  PanelHeaderButton,
  Separator,
  Text,
  Div,
  Avatar,
  Title,
  RichCell,
  Link,
  Button,
  Cell,
  List,
  FixedLayout,
} from "@vkontakte/vkui";

import { Icon28ChevronBack, Icon24Back } from "@vkontakte/icons";

const osName = platform();

const PodcastPage = ({ id, go }) => {
  const desc = localStorage.getItem("desc") || "";
  const name = localStorage.getItem("name") || "";
  const image = localStorage.getItem("image") || "";
  const times = JSON.parse(localStorage.getItem("times") || "[]");

  return (
    <Panel
      id={id}
      style={{
        paddingBottom: "40px",
      }}
    >
      <PanelHeader
        left={
          <PanelHeaderButton onClick={go} data-to="createPodcast">
            {osName === IOS ? <Icon28ChevronBack /> : <Icon24Back />}
          </PanelHeaderButton>
        }
      >
        Новый подкаст
      </PanelHeader>
      <RichCell
        disabled
        multiline
        text={<Link>ПараDogs</Link>}
        caption="Длительность:"
        before={<Avatar size={72} mode="image" src={image} />}
      >
        <Title level="3" weight="semibold">
          {name}
        </Title>
      </RichCell>

      <Separator></Separator>
      <Div>
        <Title level="3" weight="semibold">
          Описание
        </Title>
        <Text>{desc}</Text>
      </Div>
      <Separator></Separator>
      <Div>
        <Title level="3" weight="semibold">
          Содержание
        </Title>
      </Div>
      <List>
        {times.map((item, index) => (
          <Cell key={item.id}>
            <Title level="3" weight="regular">
              <Link>{item.time}</Link> &mdash; {item.label}
            </Title>
          </Cell>
        ))}
      </List>

      <FixedLayout vertical="bottom">
        <Separator wide />
        <Div
          style={{
            background: "var(--background_content)",
          }}
        >
          <Button size="xl" onClick={go} data-to="end">
            опубликовать подкаст
          </Button>
        </Div>
      </FixedLayout>
    </Panel>
  );
};

export default PodcastPage;
