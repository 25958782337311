import React, { useState } from "react";

import {
  CellButton,
  Group,
  Header,
  Input,
  Cell,
  List,
} from "@vkontakte/vkui";

import Icon28AddCircleOutline from "@vkontakte/icons/dist/28/add_circle_outline";
import Icon28RemoveCircleOutline from "@vkontakte/icons/dist/28/remove_circle_outline";

const getId = () => {
  return Math.random().toString(36).substr(2, 9);
};

const Timecodes = () => {
  const [times, setTimesWraper] = useState(
    JSON.parse(localStorage.getItem("times") || "[]")
  );

  const updateTimecode = (e, index) => {
    times[index].time = e.target.value;
    setTimes(times);
  };
  const updateLabel = (e, index) => {
    times[index].label = e.target.value;
    setTimes(times);
  };

  const setTimes = (times) => {
    setTimesWraper(times);
    localStorage.setItem("times", JSON.stringify(times));
  };

  const removeTime = (timeIndex) => {
    const newtimes = times.filter((_, index) => index !== timeIndex);
    setTimes(newtimes);
  };

  return (
    <Group
      header={<Header mode="secondary">Таймкоды</Header>}
      description="Отметки времени с название темы. Позволяют слушателям легче путешестваовать по подкасту"
    >
      <List>
        {times.map((item, index) => (
          <Cell
            key={item.id}
            asideContent={
              <Input
                style={{ width: "80px" }}
                defaultValue={item.time}
                onChange={(e) => updateTimecode(e, index)}
              />
            }
            before={
              <Icon28RemoveCircleOutline
                fill="var(--destructive)"
                onClick={(e) => removeTime(index)}
              />
            }
          >
            <Input
              defaultValue={item.label}
              onChange={(e) => {
                updateLabel(e, index);
              }}
            />
          </Cell>
        ))}
      </List>
      <CellButton
        before={<Icon28AddCircleOutline />}
        onClick={() => {
          setTimes([...times, { label: "", time: "", id: getId() }]);
        }}
      >
        Добавить таймкод
      </CellButton>
    </Group>
  );
};

export default Timecodes;
