import React, { useState, useEffect } from "react";

import Placeholder from "@vkontakte/vkui/dist/components/Placeholder/Placeholder";
import { Button, File, SimpleCell, Group } from "@vkontakte/vkui";

import Icon28PodcastOutline from "@vkontakte/icons/dist/28/podcast_outline";

const AudioPicker = ({ go, audio, setAudio }) => {
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (audio) {
      let audioTag = new Audio();
      audioTag.addEventListener("loadedmetadata", () => {
        let sec = parseInt(audioTag.duration);
        setDuration(`${parseInt(sec / 60)}:${sec % 60}`);
      });
      audioTag.src = audio.file;
    }
  }, [audio]);

  const handleChange = (event) => {
    let file = event.target.files[0];

    let fr = new FileReader();
    fr.onload = (e) => {
      setAudio({
        name: file.name,
        file: e.target.result,
      });
    };
    fr.readAsDataURL(file);
  };

  return audio ? (
    <>
      <Group description="Вы можете добавить таймкоды и скорректировать подкаст в режиме редактирования">
        <SimpleCell
          before={<Icon28PodcastOutline fill="var(--icon_tertiary)" />}
          after={
            <span style={{ color: "var(--text_tertiary)" }}>{duration}</span>
          }
        >
          {audio.name}
        </SimpleCell>
      </Group>
      <Button size="xl" mode="outline" onClick={go} data-to="audioEdit">
        Редактировать запись
      </Button>
    </>
  ) : (
    <Placeholder
      header="Загрузите Ваш подкаст"
      action={
        <File size="l" onChange={handleChange}>
          Добавить подкаст
        </File>
      }
    >
      Выберите готовый аудиофайл из вашего телефона и добавьте его.
    </Placeholder>
  );
};

export default AudioPicker;
