import React from "react";

import Card from "@vkontakte/vkui/dist/components/Card/Card";
import File from "@vkontakte/vkui/dist/components/File/File";

import Icon32PictureOutline from "@vkontakte/icons/dist/32/picture_outline";

import "./ImagePicker.css";

const ImagePicker = ({ image, setImage }) => {
  const handleChange = (event) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <Card
      size="l"
      mode="outline"
      className="podcast-cover"
      style={{
        backgroundImage: `url(${image})`,
      }}
    >
      <File mode="tertiary" onChange={handleChange}>
        <Icon32PictureOutline
          fill={image ? "var(--button_tertiary_background)" : "var(--accent)"}
        />
      </File>
    </Card>
  );
};
export default ImagePicker;
